import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import { withAttrs } from '../../utils'
import { H3 } from '../shared/type'
import Button from '../shared/button'
import Link from '../shared/link'
import BaseEmoji from '../shared/emoji'

const Container = styled.div`
  margin: ${p => `${p.theme.space[15]} 0`};
  padding: ${p => p.theme.space[8]};
  background: ${p => p.theme.colors.gray[1]};
  ${mediaQueries.sm} {
    padding: ${p => p.theme.space[14]};
  }
`

const Title = styled(H3)`
  margin: 0 0 24px 0;
  line-height: 1.25;
  ${mediaQueries.md} {
    line-height: 1.5;
  }
`

const ButtonLink = styled(withAttrs(Button, { as: Link, variant: 'outline' }))`
  background-color: transparent;
  &[href]:hover,
  &[href]:focus {
    background-color: ${p => p.theme.colors.black[0]};
    color: ${p => p.theme.colors.white};
    text-decoration: none;
  }

  ${mediaQueries.md} {
    background-color: transparent;
  }
`

const Emoji = styled(BaseEmoji)`
  margin-right: ${p => p.theme.space[2]};
`

const FeaturedLink = ({ title, to, target = '_blank', children, ...rest }) => (
  <Container {...rest}>
    <Title>{title}</Title>
    <ButtonLink to={to} target={target}>
      <Emoji symbol="💥" /> {children}
    </ButtonLink>
  </Container>
)

export default FeaturedLink
