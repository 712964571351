import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import { H2 } from '../shared/type'
import UiContainer from '../shared/container'
import { Slides, useCarousel } from '../shared/carousel'
import { PostLink } from './post-link'
import BasePostList from './post-list'

const Container = styled(UiContainer)`
  padding: 0;
  overflow: hidden;
`

const Title = styled(H2)`
  margin: ${p => `0 ${p.theme.space[6]} ${p.theme.space[12]}`};

  ${mediaQueries.md} {
    margin: ${p => `0 0 ${p.theme.space[12]}`};
  }

  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[14]};
  }
`

const PostList = styled(BasePostList)`
  display: none;

  ${mediaQueries.md} {
    display: grid;
    padding-bottom: ${p => p.theme.space[1]};
  }
`

const PostSlides = styled(Slides)`
  margin: ${p => `0 ${p.theme.space[6]}`};

  ${mediaQueries.md} {
    display: none;
  }
`

const PostSlide = styled.div`
  flex-shrink: 0;
  width: 80%;
  padding: ${p => `0 ${p.theme.space[4]} ${p.theme.space[1]} 0`};
`

const PostCarousel = ({ posts, className }) => {
  const { slidesRef } = useCarousel('related-posts')
  return (
    <PostSlides ref={slidesRef} className={className}>
      {posts.map((post, index) => (
        <PostSlide key={index}>
          <PostLink post={post} />
        </PostSlide>
      ))}
    </PostSlides>
  )
}

const RelatedPosts = ({ posts, className }) => (
  <Container className={className}>
    <Title>Tips for your marketing strategy</Title>
    <PostList posts={posts} />
    <PostCarousel posts={posts} />
  </Container>
)

export default RelatedPosts
