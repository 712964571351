import React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage } from 'gatsby-plugin-image'
import { mediaQueries } from '../../theme'
import { withAttrs } from '../../utils'
import { H2 } from '../shared/type'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${p => p.theme.space[8]};
  border: 2px solid transparent;

  ${mediaQueries.md} {
    flex-direction: row;
    gap: ${p => p.theme.space[6]};
  }

  ${mediaQueries.xl} {
    gap: ${p => p.theme.space[23]};
  }
`

const Image = styled(withAttrs(GatsbyImage, { objectFit: 'contain' }))`
  width: 100%;

  ${mediaQueries.lg} {
    max-width: 29.875rem;
    flex-grow: 1;
    flex-shrink: 0;
  }
`

const Title = styled(H2)`
  margin: 0;
  max-width: 45rem;
`

const PostHeader = ({ image, title }) => (
  <Container>
    <Title>{title}</Title>
    {image && <Image image={image} alt={title} loading="lazy" />}
  </Container>
)

export default PostHeader
