import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.65;
`

const Date = styled.span``

const ReadTime = styled.span`
  display: none;
  ${mediaQueries.lg} {
    display: inline;
  }
`

const PostInfo = ({ date, read, author }) => (
  <Container>
    <strong>{author}</strong>
    <small>
      <Date>{date}</Date>
      <ReadTime>{read && ` - ${read} min read`}</ReadTime>
    </small>
  </Container>
)

export default PostInfo
