import styled from '@emotion/styled'

const Highlight = styled.span`
  padding: ${p => p.theme.space[1]};
  background: ${p =>
    `linear-gradient(180deg, ${p.theme.colors.white} 38%,  ${p.theme.colors.gray[3]} 38%);`};

  ${p =>
    p.variant === 'accent' &&
    `
    background: ${p.theme.colors.yellow[0]};    
  `}
`

export default Highlight
