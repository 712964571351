import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import { withAttrs } from '../../utils'
import { H3 } from '../shared/type'
import Button from '../shared/button'
import Link from '../shared/link'

const Container = styled.div`
  margin: ${p => `${p.theme.space[15]} 0`};
  padding: ${p => p.theme.space[8]};
  background: ${p => p.theme.colors.gray[1]};
  ${mediaQueries.sm} {
    padding: ${p => p.theme.space[14]};
  }
`

const Title = styled(H3)`
  margin: 0;
  line-height: 1.25;
  margin-bottom: 2rem;
  ${mediaQueries.md} {
    line-height: 1.5;
  }
`

const LinkButton = styled(withAttrs(Button, { as: Link, variant: 'outline' }))`
  @media (max-width: ${p => p.theme.breakpoints[0]}) {
    padding: 1rem;
    width: 100%;
  }
`

const PostReadMe = ({
  buttonTitle = 'Learn more - 20 min read',
  to = '/start/',
  children,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <Title>{children}</Title>
      <LinkButton to={to}>{buttonTitle}</LinkButton>
    </Container>
  )
}

export default PostReadMe
