import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'

const Container = styled.div`
  margin: 0;
  box-shadow: ${p => p.theme.shadows.overlay};

  .iframe {
    width: 100%;
    height: 100%;
    margin: 0;
  }
`

const Figure = styled.figure`
  height: 260px;
  margin: ${p => `${p.theme.space[10]} 0 ${p.theme.space[12]}`};

  ${mediaQueries.sm} {
    height: 360px;
  }
`

const PostVideo = ({ url, alt = `Post video from youtube: ${url}` }) => (
  <Container>
    <Figure>
      <iframe
        src={`https://www.youtube.com/embed/${url}`}
        className="iframe"
        frameBorder="0"
        loading="lazy"
        width="640"
        height="360"
        title={alt}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Figure>
  </Container>
)

export default PostVideo
