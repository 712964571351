import React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { mediaQueries } from '../../theme'
import PostInfo from './post-info'

const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 3.75rem 1fr;
  grid-gap: ${p => `${p.theme.space[15]} ${p.theme.space[5]}`};

  ${mediaQueries.md} {
    grid-gap: ${p => p.theme.space[5]};
    grid-template-columns: 3.75rem 1fr 16.25rem;
  }
`

const PostDetails = ({ className, avatar, date, read, author }) => (
  <Container className={className}>
    <GatsbyImage image={getImage(avatar)} alt="Post details" loading="lazy" />
    <PostInfo date={date} read={read} author={author} />
  </Container>
)

export default PostDetails
