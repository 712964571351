import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { css, useTheme } from '@emotion/react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import { mediaQueries } from '../theme'
import mdxComponents from '../components/shared/mdx-components'
import Mail from '../components/resources/mail'
import Layout from '../components/layout'
import PageMetadata from '../components/page-metadata'
import Container from '../components/shared/container'
import FooterCta from '../components/footer-cta'
import { Hr as BaseHr } from '../components/shared/rules'
import Quote from '../components/resources/quote'
import FeaturedLink from '../components/resources/featured-link'
import Cta from '../components/resources/post-cta'
import PostDetails from '../components/resources/post-details'
import Header from '../components/resources/post-header'
import BaseRelatedPosts from '../components/resources/related-posts'
import TemplateLink from '../components/shared/templates/template-link'
import Highlight from '../components/resources/highlight'
import Bio from '../components/resources/bio'
import PostVideo from '../components/resources/post-video'
import PostReadMe from '../components/resources/post-read-me'
import PostChatLink from '../components/resources/post-chat-link'
import BaseRelatedTemplates from '../components/resources/related-templates'
import '@fontsource/merriweather/latin-400.css'
import '@fontsource/merriweather/latin-900.css'

const InnerContainer = styled(Container)`
  margin-bottom: ${p => p.theme.space[18]};
  ${mediaQueries.md} {
    margin-bottom: ${p => p.theme.space[23]};
  }
`

const Details = styled(PostDetails)`
  margin: ${p => `${p.theme.space[15]} 0`};
`

const Content = styled.div`
  max-width: 40rem;
  margin: 0 auto;
`

const Hr = styled(BaseHr)`
  margin: ${p => `${p.theme.space[14]} 0`};
  ${mediaQueries.md} {
    margin: ${p => `${p.theme.space[23]} 0`};
  }
`

const List = styled.ul`
  margin: ${p => `${p.theme.space[6]} 0 ${p.theme.space[8]}`};
  padding-left: ${p => p.theme.space[14]};
  font-family: Merriweather, serif;
`

const ListItem = styled.li`
  padding: ${p => `${p.theme.space[3]} 0`};
  list-style: ${p => (p.image ? `'${p.image} '` : 'none')};
  font-size: ${p => p.theme.fontSizes[3]};

  :first-of-type {
    padding-top: 0;
  }
`

const RelatedPosts = styled(BaseRelatedPosts)`
  margin-bottom: ${p => p.theme.space[18]};

  ${mediaQueries.md} {
    margin-bottom: ${p => p.theme.space[23]};
  }
`

const RelatedTemplates = styled(BaseRelatedTemplates)`
  margin-bottom: ${p => p.theme.space[18]};

  ${mediaQueries.md} {
    margin-bottom: ${p => p.theme.space[23]};
  }
`

const Template = ({ defaultTheme = 'light', target, linkText }) => {
  const theme = useTheme()
  const { images, image, ...base } = target

  return (
    <TemplateLink
      css={css`
        margin: ${theme.space[12]} 0;
        ${mediaQueries.md} {
          margin: ${theme.space[15]} 0;
        }
      `}
      defaultTheme={defaultTheme}
      target={{ ...base, image: image || images[defaultTheme] }}
    >
      {linkText || 'See live preview'}
    </TemplateLink>
  )
}

const Iframe = styled.iframe`
  width: 100%;
  max-width: 640px;
  height: 230px;
  box-shadow: ${p => p.theme.shadows.overlay};
  margin: ${p => `${p.theme.space[4]} 0 ${p.theme.space[2]}`};
  ${mediaQueries.md} {
    height: 362px;
  }
`

const PostTemplate = ({
  data: { post, relatedPosts, templates },
  location,
}) => {
  const {
    frontmatter: {
      title,
      author,
      avatar,
      read,
      date,
      description,
      keywords,
      metaTitle,
      featuredImage,
      images,
      relatedTemplates,
    },
    body,
  } = post
  const hasRelatedPosts = relatedPosts.nodes.length > 0
  const hasRelatedTemplates = relatedTemplates?.length > 0

  const templateMap = useMemo(() => {
    return templates.nodes.reduce((result, t) => {
      result[t.wid] = t
      return result
    }, {})
  }, [templates.nodes])

  const components = {
    ...mdxComponents,
    Template,
    Quote,
    FeaturedLink,
    Cta,
    Highlight,
    Bio,
    List,
    ListItem,
    Mail,
    Iframe,
    PostVideo,
    PostReadMe,
    PostChatLink,
  }

  return (
    <MDXProvider components={components}>
      <Layout location={location}>
        <PageMetadata
          title={metaTitle}
          description={description}
          keywords={keywords}
          image={featuredImage?.image.original}
          twitterCard="summary_large_image"
        />
        <InnerContainer>
          <Header title={title} image={featuredImage?.image.gatsbyImageData} />
          <Hr />
          <Content>
            <Details author={author} date={date} avatar={avatar} read={read} />
            <MDXRenderer images={images} templateMap={templateMap}>
              {body}
            </MDXRenderer>
            <Details author={author} date={date} avatar={avatar} />
          </Content>
          <Hr />
        </InnerContainer>
        {hasRelatedTemplates && (
          <RelatedTemplates
            templates={relatedTemplates.map(({ wid, defaultTheme }) => ({
              ...templateMap[wid],
              image: templateMap[wid].images[defaultTheme],
            }))}
          />
        )}
        {hasRelatedPosts && <RelatedPosts posts={relatedPosts.nodes} />}
        <FooterCta />
      </Layout>
    </MDXProvider>
  )
}

export default PostTemplate

export const query = graphql`
  query Post($slug: String!, $relatedPosts: [String]) {
    post: mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        metaTitle
        keywords
        description
        title
        author
        read
        relatedTemplates {
          defaultTheme
          wid
        }
        date(formatString: "MMMM DD, YYYY")
        images {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, formats: [PNG, WEBP, AVIF])
          }
        }
        featuredImage {
          image: childImageSharp {
            gatsbyImageData(placeholder: NONE, formats: [PNG, WEBP, AVIF])
            original {
              width
              height
              src
            }
          }
        }
        avatar {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, formats: [PNG, WEBP, AVIF])
          }
        }
      }
    }
    relatedPosts: allMdx(
      filter: { fields: { slug: { in: $relatedPosts } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        ...postPreviewFragment
      }
    }
    templates: allTemplatesYaml {
      nodes {
        ...postTemplateFragment
      }
    }
  }
`
