import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'

const Quote = styled.div`
  margin: ${p => `${p.theme.space[15]} 0`};
  padding: ${p => p.theme.space[8]};
  background: ${p => p.theme.colors.gray[1]};
  font-weight: ${p => p.theme.fontWeights.bold};
  font-size: ${p => p.theme.fontSizes[6]};
  line-height: 1.25;

  ${mediaQueries.sm} {
    line-height: 1.5;
    padding: ${p => p.theme.space[14]};
  }
`

export default Quote
