import React from 'react'
import styled from '@emotion/styled'
import useMatchMedia from '../../hooks/use-media'
import { chunkArray } from '../../utils'
import { mediaQueries } from '../../theme'
import { H2 } from '../shared/type'
import UiContainer from '../shared/container'
import { Slides, useCarousel } from '../shared/carousel'
import BaseNavigation from '../shared/carousel-navigation'
import { Hr as BaseHr } from '../shared/rules'
import TemplateList from '../shared/templates/template-list'

const Container = styled(UiContainer)`
  padding: 0;
  overflow: hidden;
`

const Title = styled(H2)`
  margin: 0;
`

const TemplateSlide = styled(TemplateList)`
  min-width: 80%;
  padding: ${p => `0 ${p.theme.space[6]} 0 0`};

  ${mediaQueries.md} {
    min-width: 100%;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    padding: 0;
  }
`

const TemplateSlides = styled(Slides)`
  margin: ${p => `0 ${p.theme.space[6]} ${p.theme.space[14]}`};

  ${mediaQueries.md} {
    margin: ${p => `0 0 ${p.theme.space[18]}`};
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${p => `0 ${p.theme.space[6]} ${p.theme.space[12]}`};

  ${mediaQueries.md} {
    margin: ${p => `0 0 ${p.theme.space[12]}`};
  }

  ${mediaQueries.lg} {
    margin: ${p => `0 0 ${p.theme.space[14]}`};
  }
`

const Navigation = styled(BaseNavigation)`
  display: none;

  ${mediaQueries.md} {
    display: flex;
  }
`

const Hr = styled(BaseHr)`
  padding: ${p => `0 ${p.theme.space[6]}`};

  ${mediaQueries.md} {
    padding: 0;
  }
`

const RelatedTemplates = ({ templates, className }) => {
  const isMobile = !useMatchMedia(mediaQueries.md)
  const chunkedTemplates = chunkArray(templates, isMobile ? 1 : 4)

  const { slidesRef, canScrollPrev, canScrollNext, scrollPrev, scrollNext } =
    useCarousel('related-templates', {
      desktop: {
        draggable: false,
      },
    })

  const navigationProps = {
    canScrollPrev,
    canScrollNext,
    onScrollToPrev: scrollPrev,
    onScrollToNext: scrollNext,
  }

  const navigationVisible = canScrollNext || canScrollPrev

  return (
    <Container className={className}>
      <Header>
        <Title>Quick start templates</Title>
        {navigationVisible && <Navigation {...navigationProps} />}
      </Header>

      <TemplateSlides ref={slidesRef}>
        {chunkedTemplates.map((chunk, index) => (
          <TemplateSlide
            key={index}
            templates={chunk}
            linkedTemplates={templates}
            defaultTheme="dark"
          />
        ))}
      </TemplateSlides>

      <Hr />
    </Container>
  )
}

export default RelatedTemplates
