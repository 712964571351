import styled from '@emotion/styled'

const Mail = styled.div`
  margin: ${p => `${p.theme.space[10]} 0 ${p.theme.space[12]}`};
  padding: ${p => p.theme.space[8]};
  font-size: ${p => p.theme.fontSizes[1]};
  line-height: 1.375;
  border: 1px solid ${p => p.theme.colors.mail.border};
  * {
    font-size: inherit;
  }
`

export default Mail
