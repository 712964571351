import React from 'react'
import styled from '@emotion/styled'
import { gtmEvent } from '../../utils/gtm'

const ChatLink = styled.span`
  color: ${p => p.theme.colors.black};
  text-decoration: underline;
  cursor: pointer;

  :hover {
    color: ${p => p.theme.colors.white};
    text-decoration: none;
    background-color: ${p => p.theme.colors.black};
  }
`

const PostChatLink = ({ children }) => (
  <ChatLink onClick={() => gtmEvent('showChat')}>{children}</ChatLink>
)

export default PostChatLink
