import React from 'react'
import styled from '@emotion/styled'
import { mediaQueries } from '../../theme'
import { withAttrs } from '../../utils'
import { H3 } from '../shared/type'
import Button from '../shared/button'
import Link from '../shared/link'

const Container = styled.div`
  margin: ${p => `${p.theme.space[15]} 0`};
  padding: ${p => p.theme.space[8]};
  background: ${p => p.theme.colors.gray[1]};
  ${mediaQueries.sm} {
    padding: ${p => p.theme.space[14]};
  }
`

const Title = styled(H3)`
  margin: 0;
  line-height: 1.25;
  ${mediaQueries.md} {
    line-height: 1.5;
  }
`

const Description = styled.div`
  margin: ${p => `${p.theme.space[6]} 0 ${p.theme.space[8]}`};
  p {
    margin: 0;
    display: inline;
    ${mediaQueries.md} {
      display: block;
    }
  }
`

const StartButton = styled(withAttrs(Button, { as: Link }))``

const PostCta = ({
  title = 'Try Getform today',
  buttonTitle = 'Get started',
  to = '/start/',
  children,
  ...rest
}) => (
  <Container {...rest}>
    <Title>{title}</Title>
    <Description>{children}</Description>
    <StartButton to={to}>{buttonTitle}</StartButton>
  </Container>
)

export default PostCta
